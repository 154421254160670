export const messages = {
  pt: {
    translations: {
      header: {
        theCamara: "A Câmara",
        solutions: "Soluções",
        blog: "Blog",
        depositions: "Depoimentos",
        workWithUs: "Trabalhe Conosco",
        requestAQuote: "Solicite um orçamento",
      },
      heroHome: {
        title: "Inovação sem limites",
        description:
          "Conecte-se com times multidisciplinares para construir e alavancar a sua startup. Aqui a rede de parcerias acontece!",
        button: "Nossas soluções",
      },
      aboutUs: {
        title: "SOBRE NÓS",
        subTitle: "Ágil, Lean e Desing Thinking",
        description:
          "A Câmara de Inovação Aberta surgiu a partir da demanda em estruturar startups e validar ideias através de Metodologias Ágeis, Design Thinking e Cultura Lean. Nossa missão é escalar negócios baseados em inovação, tecnologia, colaboração e cultura.",
        button: "Saiba mais sobre nós",
      },
      ourPartners: {
        title: "Nossos parceiros",
      },
      whatWeOffer: {
        title: "O que nós oferecemos",
        card1: {
          title: "Desenvolvimento de Softwares baseado em scrum",
          description:
            "Desenvolvemos projetos seguindo a metodologia de reuniões diárias para avaliar o andamento das atividades e planning´s por semanais para realizar o planejamento.",
        },
        card2: {
          title: "Capacitação de equipes em programação",
          description:
            "Ao trabalhar com Inovação Aberta, treinamos equipes de programadores durante a execução dos projetos. Temos 12 anos de experiência desenvolvendo um “código-fonte mãe” e ao executar um projeto sempre treinamos os programadores externos a utilizarem-no com o máximo de qualidade.",
        },
        card3: {
          title: "Liderança em cultura de inovação para a empresa",
          description:
            "Em virtude da experiência adquirida em mais de 12 anos e baseada na cultura lean ensinamos aos gestores como aplicar os conceitos no cotidiano do negócio.",
        },
        card4: {
          title: "Liderança em cultura de marketing para a empresa",
          description:
            "Através da metodologia Growth Marketing, auxiliamos nossos clientes com seus projetos que necessitam de constante crescimento exponencial, esse modelo tem o objetivo de crescimento ágil.",
        },
        card5: {
          title: "Liderança com equipe a contratar/existente",
          description:
            "Auxiliamos a inserção na cultura Lean, através de capacitações sobre inovação, autogerenciamento, empreendedorismo, design thiking, entre outras temáticas importantes na construção de equipe sinérgicas com o propósito.",
        },
        card6: {
          title: "Operação da empresa junto ao cliente",
          description:
            "Assessoramos a área financeira, Recursos Humanos, Projetos e Marketing. Compartilhando métodos de organização e gestão da inovação.",
        },
        card7: {
          title: "Mentoria em ecossistema de inovação",
          description:
            "Ensinamos meios de inserir a startup no ecossistema e construir networking para futuras parcerias e investimento.",
        },
        card8: {
          title: "Escrever editais",
          description:
            "Desenvolvemos escopo de projetos, baseados nas ideias dos clientes e de acordo com o objetivo dos editais. Além disso, realizamos a organização da documentação e submissão no sistema.",
        },
        card9: {
          title: "Processos seletivos",
          description:
            "Selecionamos profissionais compatíveis com as necessidades do cliente e em parceria com RH Tech. A prioridade é a seleção de perfis juniores que tenham características empreendedoras e almejem futura sociedade com o negócio.",
        },
      },
      depoiments: {
        one:'A Câmara tem uma forma de condução inovadora , interativa e que ajuda a compreensão dos participantes.',
        second:'O modelo de trabalho da Câmara mudou a forma como lidamos com inovação dentro da empresa. Expandimos para a maioria de nossos departamentos a cultura que a Câmara propõe.',
        three:'A Câmara sempre nos surpreende ao sugerir soluções que vão além do escopo contratado. É muito perceptível o compromisso da empresa em agregar valor ao cliente.'
      },
      footer: {
        description:
          "A Câmara de Inovação Aberta surgiu a partir da demanda em estruturar startups e validar ideias através de Metodologias Ágeis, Design Thinking e Cultura Lean",
        links: {
          theChamber: "A Câmara",
          solutions: "Soluções",
          blog: "Blog",
          testimonial: "Depoimentos",
        },
        talkToUs: {
          title: "Fale Conosco",
          phone: "Telefone",
          email: "E-mail",
          address: "Endereço",
        },
        selected: {
          default: "Selecione um idioma",
        },
        messageSolvum: "Todos os direitos reservados | Site criado com 🤍 por",
      },
      whyWorkUs: {
        title: "Por que trabalhar conosco?",
        description:
          "A Câmara é um ambiente que amplifica as oportunidades de desenvolvimento pessoal e profissional por permitir ao colaborador que exponha e alinhe seus objetivos às funções na organização. Além disso, construiu a cultura organizacional baseada em flexibilidade, confiança e colaboração.",
        button: "Quero Fazer Parte",
      },
      AboutOurTeam: {
        title: "Sobre nossa equipe",
        description:
          "Entendemos que a equipe da Câmara são todos aqueles que contribuem com o desenvolvimento da startup e do ecossistema de Inovação. Assim, aproveitamos nosso espaço para construir negócios seguindo os pilares da inovação aberta, cooperação e agilidade. Buscando constantemente a harmonia da equipe com os projetos e o progresso de todos os envolvidos.",
        button: "Quero Fazer Parte",
      },
      bePartOfTheTeam: {
        title: "Faça parte do time",
        redirect: {
          home: "Início",
          workWithUs: "Trabalhe Conosco",
        },
      },
      theChamber: {
        title: "A Câmara",
        redirect: {
          home: "Início",
          workWithUs: "A Câmara",
        },
      },
      containerProduction: {
        title: "Open Innovation Consulting",
        firstDescription:
          "A Câmara de Inovação Aberta é especializada em construir startups. Assim, desenvolvemos toda estrutura necessária para deixá-las pronta para o mercado através da Metodologia Scrum, Design Thinking e Cultura Lean. Nosso intuito, além de escalar startups, é fortalecer o ecossistema de Inovação através de parcerias e inserção de novos negócios, por esse motivo seguimos o conceito de Inovação Aberta, onde a colaboração de variados atores é um dos pilares para o progresso dos projetos.",
        lastDescription:
          "Além das entregas finais, assessoramos diariamente as equipes estruturadas especificamente para os projetos e promovemos mentorias para que os envolvidos também dominem a cultura e os processos. Nosso principal diferencial é a “mão na massa”, fazemos acontecer.",
      },
      ourMethodologies: {
        title: "O que oferecemos",
        card1: {
          title: "Metodologia Scrum",
          description:
            "Para gerir os projetos com agilidade e qualidade utilizamos essa metodologia ágil. Em cada ciclo de trabalho, ocorrem reuniões de planejamento e encontros diários de no máximo 15 minutos para acompanhamento das execuções.",
        },
        card2: {
          title: "Design Thinking",
          description:
            "De forma colaborativa, a equipe se une para desenvolver soluções para as dores e desejos dos clientes de forma criativa e inovadora.",
        },
        card3: {
          title: "Cultura Lean",
          description:
            "Utilizamos a abordagem para minimizar e excluir desperdícios de tempo, recursos financeiros e produtividade na construção das startups, além de inserir a cultura na organização.",
        },
      },
      benefits: {
        item1: {
          title: "Ampla experiência em criação e gerenciamento de projetos",
          description:
            "Sabemos estruturar novos negócios e alinhar os fatores necessários para o sucesso devido às experiências e ao compromisso de fazer sempre o melhor.",
        },
        item2: {
          title: "Serviço personalizado",
          description:
            "A equipe é ajustada para estar trabalhando no projeto de forma full time com o objetivo de contemplar todas as essencialidades do projeto, bem como se ajustar ao tempo e disponibilidade do contratante.",
        },
        item3: {
          title: "Execução e acompanhamento das atividades",
          description:
            "Não entregamos somente um produto final. Executamos todas as etapas em conjunto com a equipe, além de acompanhar constantemente a efetividade das ações.",
        },
      },
      beOne: {
        title: "Seja um Camnector",
      },
      scholarship: {
        step: "Passo",
        step1: {
          content: "Inscrição (e-mail)",
        },
        step2: {
          content: "Processo seletivo (DISC, currículo e entrevista);",
        },
        step3: {
          content: "Cronograma a ser seguido (divulgar cronogramas no site)",
        },
        step4: {
          content:
            "Acompanhamento de um orientador (reuniões semanais para passar atividades e tirar dúvidas, orientador estará disponível para fazer acompanhamento mais frequente se o bolsista precisar)",
        },
        step5: {
          content: "Atividades semanais",
        },
        step6: {
          content: "Atuação em projetos",
        },
      },
      beACamnector: {
        title: "Seja um Camnector",
        links: {
          start: "Home",
          beACamnector: "Be a Camnector",
        },
        why: {
          title: "O que é A Câmara de Inovação?",
          description:
            "A Câmara de Inovação Aberta é um espaço onde a colaboração é a base para o desenvolvimento e sucesso dos projetos. Acreditamos que os atores do ecossistema de Inovação têm muito a agregar. Assim, só temos motivos para abrir a porta da nossa empresa para novas personalidades, experiências e conhecimento. Associe-se ao nosso programa Camnectors, o objetivo é recrutar pessoas e empresas dispostas a inovar e colaborar. Aguardamos você!!",
          title2: "Camnectors",
          description2:
            "Os Camnectors é um programa da Câmara de Inovação Aberta que objetiva recrutar pessoas que tenham afinidade e vontade de contribuir com o desenvolvimento do ecossistema de inovação através da participação em projetos. Para mais informações sobre como funcionará o processo, acesse o link abaixo:",
          accessMaterial: "Acessar matérial",
        },
        benefits: {
          title: "Beneficios",
          one: "Acesso a eventos",
          two: "Comissão ou benefícios em projetos que tenha experiência",
          three: "Colaboração em projetos em funções que tenha experiência",
          four: "Acesso às trilhas de capacitação da Câmara",
          five: "Assinatura de e-mail exclusiva",
          six: "Cartão de visita digital",
          seven: "Concorrer a bolsas de estudo para aperfeiçoamento",
        },
        restrictions: {
          one: "Utilizar a imagem da Câmara apenas para intermediar contatos",
          two: "Dar benefícios a clientes somente se acordado com a diretoria responsável",
          three:
            "Tomar decisões podem ser tomadas alinhadas a alguém da diretoria",
          four: "Não assinar documentos em nome da Câmara.",
        },
      },
    },
  },
};
