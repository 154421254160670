import { Header } from "components/Header";
import { ScholarshipProgram } from "components/ScholarshipProgram";
import { motion } from "framer-motion";
import { itemVariants, variants } from "animations";
import { Link } from "react-router-dom";
import { ArrowUpRight, CaretRight, Check } from "phosphor-react";
import "styles/pages/beACamnector.scss";
import { i18n } from "translate/i18n";
import { Footer } from "components/Footer";

export function BeACamnector() {
  return (
    <>
      <Header />
      <main className="heroContainer">
        <motion.div variants={variants} initial="hidden" animate="visible">
          <motion.h1 variants={itemVariants}>
            {i18n.t("beACamnector.title")}
          </motion.h1>
          <motion.span variants={itemVariants}>
            <Link to="/">{i18n.t("beACamnector.links.start")}</Link>
            <CaretRight color="#6a727f" size={16} weight="bold" />
            <Link to="/acamara">
              {i18n.t("beACamnector.links.beACamnector")}
            </Link>
          </motion.span>
        </motion.div>
      </main>

      <section className="container">
        <div className="whyCam">
          <h2>{i18n.t("beACamnector.why.title")}</h2>
          <p>{i18n.t("beACamnector.why.description")}</p>
          <h2>{i18n.t("beACamnector.why.title2")}</h2>
          <p>{i18n.t("beACamnector.why.description2")}</p>

          <a href="#">
            {i18n.t("beACamnector.why.accessMaterial")}{" "}
            <ArrowUpRight size={16} weight="bold" />
          </a>
        </div>
      </section>

      <section className="benefitsContainer">
        <div className="title">
          <h2>{i18n.t("beACamnector.benefits.title")}</h2>

          <svg
            width="195"
            height="15"
            viewBox="0 0 195 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M194 7.125L194.597 7.92723C194.858 7.73281 195.008 7.42337 195 7.09785C194.991 6.77233 194.824 6.4715 194.553 6.29155L194 7.125ZM185.314 0.166548C184.854 -0.13864 184.234 -0.0128946 183.928 0.447408C183.623 0.907711 183.749 1.52826 184.209 1.83345L185.314 0.166548ZM184.165 13.1978C183.722 13.5275 183.63 14.154 183.96 14.597C184.289 15.0401 184.916 15.132 185.359 14.8022L184.165 13.1978ZM0 8.125H194V6.125H0V8.125ZM194.553 6.29155L185.314 0.166548L184.209 1.83345L193.447 7.95845L194.553 6.29155ZM193.403 6.32277L184.165 13.1978L185.359 14.8022L194.597 7.92723L193.403 6.32277Z"
              fill="url(#paint0_linear_3_2)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3_2"
                x1="180.143"
                y1="-18.5003"
                x2="1.70488"
                y2="5.292"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F25D23" />
                <stop offset="1" stop-color="#F25D23" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div>
          <ul className="groupGrid">
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.one")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.two")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.three")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.four")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.five")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.six")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.benefits.seven")}
            </li>
          </ul>
        </div>
      </section>

      <section className="restrictionsContainer">
        <div>
          <ul className="groupGrid">
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.restrictions.one")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.restrictions.two")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.restrictions.three")}
            </li>
            <li>
              <Check size={16} weight="bold" />
              {i18n.t("beACamnector.restrictions.four")}
            </li>
          </ul>
        </div>
        <div className="title">
          <h2>Benefícios</h2>

          <svg
            width="195"
            height="15"
            viewBox="0 0 195 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M194 7.125L194.597 7.92723C194.858 7.73281 195.008 7.42337 195 7.09785C194.991 6.77233 194.824 6.4715 194.553 6.29155L194 7.125ZM185.314 0.166548C184.854 -0.13864 184.234 -0.0128946 183.928 0.447408C183.623 0.907711 183.749 1.52826 184.209 1.83345L185.314 0.166548ZM184.165 13.1978C183.722 13.5275 183.63 14.154 183.96 14.597C184.289 15.0401 184.916 15.132 185.359 14.8022L184.165 13.1978ZM0 8.125H194V6.125H0V8.125ZM194.553 6.29155L185.314 0.166548L184.209 1.83345L193.447 7.95845L194.553 6.29155ZM193.403 6.32277L184.165 13.1978L185.359 14.8022L194.597 7.92723L193.403 6.32277Z"
              fill="url(#paint0_linear_3_2)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3_2"
                x1="180.143"
                y1="-18.5003"
                x2="1.70488"
                y2="5.292"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F25D23" />
                <stop offset="1" stop-color="#F25D23" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>
      <ScholarshipProgram />
      <Footer />
    </>
  );
}
