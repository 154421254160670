import {
  Calendar,
  ClockClockwise,
  EnvelopeOpen,
  ProjectorScreenChart,
  Scroll,
  ShareNetwork,
} from "phosphor-react";
import { Check, Clock, Sheets } from "./BenefitsIcon";
import { CoffeeLovely } from "./CoffeeLovely";
import {
  Dribble,
  Face,
  Insta,
  Linkedin,
  Twitter,
  Youtube,
} from "./SocialRedes";

export function Icon(props: any) {
  switch (props.name.toLowerCase()) {
    case "insta":
      return <Insta {...props} />;
    case "face":
      return <Face {...props} />;
    case "dribble":
      return <Dribble {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "sheets":
      return <Sheets {...props} />;
    case "check":
      return <Check {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "coffeelovely":
      return <CoffeeLovely {...props} />;
    case "youtube":
      return <Youtube />;
    case "linkedin":
      return <Linkedin />;
    case "email":
      return <EnvelopeOpen size={54} weight="bold" color="#f3f6fa" />;
    case "scroll":
      return <Scroll size={54} weight="bold" color="#f3f6fa" />;
    case "calendar":
      return <Calendar size={54} weight="bold" color="#f3f6fa" />;
    case "screen":
      return <ProjectorScreenChart size={54} weight="bold" color="#f3f6fa" />;
    case "clockwise":
      return <ClockClockwise size={54} weight="bold" color="#f3f6fa" />;
    case "network":
      return <ShareNetwork size={54} weight="bold" color="#f3f6fa" />;
    default:
      return <div>Insira um name válido para carregar um icone</div>;
  }
}
