import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import DeveloperIcon from "assets/images/developerInScrum.png";
import ProgrammerGroupIcon from "assets/images/programmerGroup.png";
import RocketIcon from "assets/images/rocket.png";
import MegaphoneIcon from "assets/images/megaphone.png";
import ThingOfInOuthBoxIcon from "assets/images/thingOfInOutBox.png";
import HandShakeIcon from "assets/images/handshake.png";
import InnovationIcon from "assets/images/innovation.png";
import FolterIcon from "assets/images/folder.png";
import TalentIcon from "assets/images/talentSearch.png";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";

import "./styles.scss";
import { i18n } from "translate/i18n";
// install Swiper modules
SwiperCore.use([Pagination]);

export const offerings = [
  {
    id: 1,
    image: DeveloperIcon,
    title: i18n.t("whatWeOffer.card1.title"),
    description: i18n.t("whatWeOffer.card1.description"),
  },
  {
    id: 2,
    image: ProgrammerGroupIcon,
    title: i18n.t("whatWeOffer.card2.title"),
    description: i18n.t("whatWeOffer.card2.description"),
  },
  {
    id: 3,
    image: RocketIcon,
    title: i18n.t("whatWeOffer.card3.title"),
    description: i18n.t("whatWeOffer.card3.description"),
  },
  {
    id: 4,
    image: MegaphoneIcon,
    title: i18n.t("whatWeOffer.card4.title"),
    description: i18n.t("whatWeOffer.card4.description"),
  },
  {
    id: 5,
    image: ThingOfInOuthBoxIcon,
    title: i18n.t("whatWeOffer.card5.title"),
    description: i18n.t("whatWeOffer.card5.description"),
  },
  {
    id: 6,
    image: HandShakeIcon,
    title: i18n.t("whatWeOffer.card6.title"),
    description: i18n.t("whatWeOffer.card6.description"),
  },
  {
    id: 7,
    image: InnovationIcon,
    title: i18n.t("whatWeOffer.card7.title"),
    description: i18n.t("whatWeOffer.card7.description"),
  },
  {
    id: 8,
    image: FolterIcon,
    title: i18n.t("whatWeOffer.card8.title"),
    description: i18n.t("whatWeOffer.card8.description"),
  },
  {
    id: 9,
    image: TalentIcon,
    title: i18n.t("whatWeOffer.card9.title"),
    description: i18n.t("whatWeOffer.card9.description"),
  },
];

export function SlideSolution() {
  const params = {
    slidesPerView: 3,
    spaceBetween: 40,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      "200": {
        slidesPerView: 1,
        spaceBetween: 10,
        slidesPerGroup: 1,
      },
      "660": {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 2,
      },
      "964": {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
      },
    },
    loop: true,
    grabCursor: true,
    autoplay: true,
  };

  return (
    <Swiper {...params} className="solutionContainer">
      {offerings.map((offering) => (
        <SwiperSlide key={offering.id} className="solution">
          <img src={offering.image} alt="planner de notas" />
          <span>{offering.title}</span>
          <p>{offering.description}</p>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
