import { BeACamnector } from "pages/be-a-camnector";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Acamara } from "./pages/acamara";
import { Home } from "./pages/home";
import { TrabalheConosco } from "./pages/trabalhe-conosco";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/acamara" exact component={Acamara} />
        <Route path="/work-with-us" exact component={TrabalheConosco} />
        <Route path="/be-a-camnector" exact component={BeACamnector} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
