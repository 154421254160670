export const messages = {
  en: {
    translations: {
      header: {
        theCamara: "The Chamber",
        solutions: "Solutions",
        blog: "Blog",
        depositions: "Testimonial",
        workWithUs: "Work with us",
        requestAQuote: "Request a quote",
      },
      heroHome: {
        title: "Innovation without limits",
        description:
          "Connect with multidisciplinary teams to leverage your startup. Here the partnership network happens!",
        button: "Our solutions",
      },
      aboutUs: {
        title: "ABOUT US",
        subTitle: "Agile, Lean and Design Thinking",
        description:
          "The Câmera de Inovação Aberta emerged from the demand in structuring startups and validate ideas through Agile Methodologies, Design Thinking and Lean Culture. Our mission is to scale businesses based on innovation, technology, collaboration and culture.",
        button: "Learn more about us",
      },
      ourPartners: {
        title: "Our partners",
      },
      whatWeOffer: {
        title: "What we offer",
        card1: {
          title: "Software Development based on Scrum",
          description:
            "We develop projects following the methodology of daily meetings to evaluate the progress of the activities and weekly meetings to do the planning.",
        },
        card2: {
          title: "Team training in programming",
          description:
            "When working with Open Innovation, we train programmer teams during the execution of the projects. We have 12 years of experience developing a 'mother code' and when executing a project we always train the external programmers to use it with the maximum quality.",
        },
        card3: {
          title: "Leadership in innovation culture for the company",
          description:
            "The experience acquired in more than 12 years and grounding in lean culture, we teach managers how to apply the concepts in their daily business.",
        },
        card4: {
          title: "Leadership in marketing culture for the company",
          description:
            "Through the Growth Marketing methodology, we help our clients with their projects that need constant exponential growth, this model has the goal of agile growth.",
        },
        card5: {
          title: "Leadership with hiring/existing teams",
          description:
            "We assist the insertion in the Lean culture through training on innovation, self-management, entrepreneurship, design thinking, among other important themes in building synergistic teams with purpose.",
        },
        card6: {
          title: "Company operation with the customer",
          description:
            "We assist the financial area, Human Resources, Projects, and Marketing. Sharing organization and innovation management methods.",
        },
        card7: {
          title: "Innovation ecosystem mentoring",
          description:
            "We teach ways to insert the startup in the ecosystem and build networking for future partnerships and investment.",
        },
        card8: {
          title: "Writing edicts",
          description:
            "We develop project scopes, according to the client's ideas and to the objective of the edicts. In addition, we organize the documentation and submission in the system.",
        },
        card9: {
          title: "Selective processes",
          description:
            "We select professionals compatible with the client's needs and in partnership with HR Tech. The priority is to select junior profiles who have entrepreneurial characteristics and aspire a future partnership with the business.",
        },
      },
      depoiments: {
        one:'The Câmara has an innovative, interactive way of conducting things that helps participants understand.',
        second:'The Câmara working model has changed the way we deal with innovation within the company. We have expanded the culture that the Câmara proposes to most of our departments.',
        three:'The Câmara always surprises us by suggesting solutions that go beyond the contracted scope. The company commitment to adding value to the customer is highly guaranteed.'
      },
      footer: {
        description:
          "The Open Innovation Chamber came from the demand in structuring startups and validating ideas through Agile Methodologies, Design Thinking and Lean Culture",
        links: {
          theChamber: "The Chamber",
          solutions: "Solutions",
          blog: "Blog",
          testimonial: "Testimonial",
        },
        talkToUs: {
          title: "Talk To Us",
          phone: "Phone",
          email: "Email",
          address: "Address",
        },
        selected: {
          default: "Select a language",
        },
        messageSolvum: "All rights reserved | Website created with 🤍 by",
      },
      whyWorkUs: {
        title: "Why work with us?",
        description:
          "The Chamber is an environment that expands opportunities for personal and professional development by allowing employees to expose and align their goals with their roles in the organization. In addition, it has built an organizational culture based on flexibility, trust, and collaboration.",
        button: "Start",
      },
      AboutOurTeam: {
        title: "About our team",
        description:
          "We understand that the Chamber team are all those who contribute to the development of the startup and Innovation ecosystem. Thus, we take advantage of our space to build businesses following the pillars of open innovation, cooperation and agility. Constantly seeking the harmony of the team with the projects and the progress of all involved.",
        button: "Start",
      },
      bePartOfTheTeam: {
        title: "Be part of the team",
        redirect: {
          home: "Home",
          workWithUs: "Work With Us",
        },
      },
      theChamber: {
        title: "About us",
        redirect: {
          home: "Home",
          workWithUs: "About us",
        },
      },
      containerProduction: {
        title: "Open Innovation Consulting",
        firstDescription:
          "The Open Innovation Chamber is specialized in startups building. We develop all the necessary structure to make them ready for the market through Scrum Methodology, Design Thinking and Lean Culture. Our intention, besides scaling startups, is to strengthen the innovation ecosystem through partnerships and the insertion of new business, for this reason we follow the concept of Open Innovation, where the collaboration of various agents form the pillars for the progress of projects.",
        lastDescription:
          "In addition to the final deliveries, we daily advise the teams structured specifically for the projects and promote mentoring as well, so those involved can also master the culture and processes. Our main differential is the “hands on”, we make it happen.",
      },
      ourMethodologies: {
        title: "What we offer",
        card1: {
          title: "Scrum Methodology",
          description:
            "To manage the projects with agility and quality we use this agile methodology. In each work cycle, there are planning meetings and daily meetings of no more than 15 minutes to monitor the execution.",
        },
        card2: {
          title: "Design Thinking",
          description:
            "In a collaborative way the team comes together to develop solutions for the clients' needs and wants in a creative and innovative way.",
        },
        card3: {
          title: "Lean Culture",
          description:
            "We use the approaching to minimize and exclude the waste of time, financial resources and productivity in startup building, and and embed the culture in the organization.",
        },
      },
      benefits: {
        item1: {
          title: "Extensive experience in project creation and management",
          description:
            "We know how to structure new businesses and align the factors necessary for success due to experience and commitment to always do the best",
        },
        item2: {
          title: "Personalized Service",
          description:
            "The project team is adjusted to contemplate all the essentials of the project, as well as to fit the contractor's time and availability.",
        },
        item3: {
          title: "Execution and monitoring of activities",
          description:
            "We do not deliver just a final product. We execute all the stages together with the team, and constantly monitor the effectiveness of the actions.",
        },
      },
      beOne: {
        title: "Be a Camnector",
      },
      scholarship: {
        step: "Step",
        step1: {
          content: "Registration (email)",
        },
        step2: {
          content: "Selection process (DISC, resume and interview)",
        },
        step3: {
          content: "Schedule to be followed (publish schedules on the website)",
        },
        step4: {
          content:
            "Accompaniment by a mentor (weekly meetings to pass activities and ask questions, mentor will be available to follow up more frequently if the scholarship holder needs it)",
        },
        step5: {
          content: "Weekly activities",
        },
        step6: {
          content: "Working on projects",
        },
      },
      beACamnector: {
        title: "Be a Camnector",
        links: {
          start: "Home",
          beACamnector: "Be a Camnector",
        },
        why: {
          title: "What is A Câmara de Inovação?",
          description:
            "A Câmara de Inovação Aberta is a space where collaboration is the basis for the development and success of projects. We believe that the actors in the Innovation ecosystem have a lot to add. Thus, we only have reasons to open the door of our company to new personalities, experiences and knowledge. Join our Camnectors program, the goal is to recruit people and companies willing to innovate and collaborate. We are waiting for you!!",
          title2: "Camnectors",
          description2:
            "The Camnectors is a program of the Open Innovation Chamber that aims to recruit people who have affinity and desire to contribute to the development of the innovation ecosystem through participation in projects. For more information on how the process will work, visit the link below:",
          accessMaterial: "Access material",
        },
        benefits: {
          title: "Benefits",
          one: "Access to events",
          two: "Commission or benefits on projects you have experience",
          three: "Collaboration on projects in roles that you have experience",
          four: "Access to Chamber training trails",
          five: "Unique email signature",
          six: "Digital Business Card",
          seven: "Apply for scholarships for improvement",
        },
        restrictions: {
          one: "Use the Camera image only to intermediate contacts",
          two: "Give benefits to customers only if agreed with the responsible management",
          three:
            "Making decisions can be made in line with someone on the board",
          four: "Do not sign documents on behalf of the Chamber.",
        },
      },
    },
  },
};
