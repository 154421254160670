import { Link, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";

import { Button } from "../Button";

import LogoImg from "../../assets/images/logo.png";

import "./styles.scss";
import { NavbarMobile } from "./mobile";
import { FlagContainer } from "components/FlagContainer";
import { CaretDown } from "phosphor-react";

export function Header() {
  const history = useHistory();

  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  function changeBackground() {
    if (window.scrollY >= 80) {
      setIsShowNavbar(true);
    } else {
      setIsShowNavbar(false);
    }
  }

  function handleOpenDropdown() {
    setIsOpenDropdown(true);
  }

  function handleCloseDropdown() {
    setIsOpenDropdown(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, [isShowNavbar]);

  return (
    <header className={`headerContainer ${isShowNavbar && "showBackground"}`}>
      <nav className="menuDesktopContainer">
        <div>
          <Link to="/">
            <img src={LogoImg} alt="logo Câmara de Inovação" />
          </Link>
        </div>
        <ul>
          <li>
            <a href="/acamara" className="navLink">
              {i18n.t("header.theCamara")}
            </a>
          </li>
          <li>
            <a
              href="https://camaradeinovacao.com/blog/nossos-projetos/"
              className="navLink"
            >
              {i18n.t("header.solutions")}
            </a>
          </li>
          <li>
            <a
              href="https://camaradeinovacao.com/blog/conteudos/"
              className="navLink"
            >
              {i18n.t("header.blog")}
            </a>
          </li>
          <li>
            <button
              className="navLink dropdownButton"
              onMouseEnter={handleOpenDropdown}
              onMouseLeave={handleCloseDropdown}
            >
              <span>{i18n.t("header.depositions")}</span>

              <CaretDown size={16} weight="bold" />
            </button>

            <ul
              className={`dropdownContent ${isOpenDropdown && "open"}`}
              onMouseEnter={handleOpenDropdown}
              onMouseLeave={handleCloseDropdown}
            >
              <li>
                <a href="/be-a-camnector" className="navLink">
                  Camnector
                </a>
              </li>
              <li>
                <a href="/work-with-us" className="navLink">
                  {i18n.t("header.workWithUs")}
                </a>
              </li>
            </ul>
          </li>

          <a href="https://camaradeinovacao.com/blog/orcamento/">
            <Button type="button">{i18n.t("header.requestAQuote")}</Button>
          </a>
        </ul>
        <FlagContainer />
      </nav>
      <NavbarMobile />
    </header>
  );
}
