import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import PointsClient from "assets/images/pointsClient.png";
// import FacePeople from "assets/images/facePeople.png";
import IgorPeople from "assets/images/igor_icon.png";
import AnaPeople from "assets/images/ana_icon.png";
import MateusPeople from "assets/images/mateus_icon.png";
import { i18n } from "translate/i18n";


import "./styles.scss";
// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);

export function SlideClient() {
  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    loop: true,
    grabCursor: true,
    autoplay: true,
  };

  return (
    <Swiper {...params} className="swiperContainer">
      <SwiperSlide className="despoilment">
        <div className="slideImage">
          <img src={PointsClient} alt="Conjunto de pontos" />
          <img src={IgorPeople} className="rounded" alt="Foto de perfil do cliente Câmara" />
        </div>
        <div className="slideText">
          <p>
          {i18n.t("depoiments.one")}
          </p>
          <br />

          <span>Igor Fonseca - Chargeback</span>
        </div>
      </SwiperSlide>
      <SwiperSlide className="despoilment">
        <div className="slideImage">
          <img src={PointsClient} alt="Conjunto de pontos" />
          <img src={AnaPeople} className="rounded"  alt="Foto de perfil do cliente Câmara" />
        </div>
        <div className="slideText">
          <p>
          {i18n.t("depoiments.second")}
          </p>
          <br />

          <span>Ana Virgínia - Ajudando</span>
        </div>
      </SwiperSlide>
      <SwiperSlide className="despoilment">
        <div className="slideImage">
          <img src={PointsClient} alt="Conjunto de pontos" />
          <img src={MateusPeople} className="rounded" alt="Foto de perfil do cliente Câmara" />
        </div>
        <div className="slideText">
          <p>
          {i18n.t("depoiments.three")}
          </p>
          <br />
          <span>Mateus - Vigilante 2.0</span>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
