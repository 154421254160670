import { itemVariants, variants } from "animations";
import { Footer } from "components/Footer";
import { Icon } from "components/Icons";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import AboutCamaraImg from "assets/images/section1-aboutCamara.png";
import P2PBlack from "assets/images/p2pBlack.png";
import SrdBlack from "assets/images/srdBlack.png";
import MaqhinBlack from "assets/images/maqhinBlack.png";
import SolvumBlack from "assets/images/solvumBlack.png";

import "styles/pages/acamara.scss";
import { Header } from "components/Header";
import { i18n } from "translate/i18n";

export function Acamara() {
  return (
    <>
      <Header />

      <main className="heroContainer">
        <motion.div variants={variants} initial="hidden" animate="visible">
          <motion.h1 variants={itemVariants}>
            {i18n.t("theChamber.title")}
          </motion.h1>
          <motion.span variants={itemVariants}>
            <Link to="/">{i18n.t("theChamber.redirect.home")}</Link>
            <Link to="/acamara">
              {i18n.t("theChamber.redirect.workWithUs")}
            </Link>
          </motion.span>
        </motion.div>
      </main>

      <section className="containerProduction production">
        <div className="prodText">
          <h2>{i18n.t("containerProduction.title")}</h2>
          <p>{i18n.t("containerProduction.firstDescription")}</p>
          <p>{i18n.t("containerProduction.lastDescription")}</p>
        </div>
        <div className="prodImage">
          <img src={AboutCamaraImg} alt="Sobre a Câmara" />
        </div>
      </section>

      <section className="containerColumn ourMethodologies">
        <h2>{i18n.t("ourMethodologies.title")}</h2>

        <div className="flexCards">
          <div>
            <span>{i18n.t("ourMethodologies.card1.title")}</span>
            <p>{i18n.t("ourMethodologies.card2.description")}</p>
          </div>
          <div>
            <span>{i18n.t("ourMethodologies.card2.title")}</span>
            <p>{i18n.t("ourMethodologies.card2.description")}</p>
          </div>
          <div>
            <span>{i18n.t("ourMethodologies.card3.title")}</span>
            <p>{i18n.t("ourMethodologies.card3.description")}</p>
          </div>
        </div>
      </section>

      <section className="container benefits">
        <div>
          <Icon name="sheets" />
          <h4>{i18n.t("benefits.item1.title")}</h4>
          <p>{i18n.t("benefits.item1.description")}</p>
        </div>
        <div>
          <Icon name="check" />
          <h4>{i18n.t("benefits.item2.title")}</h4>
          <p>{i18n.t("benefits.item2.description")}</p>
        </div>
        <div>
          <Icon name="clock" />
          <h4>{i18n.t("benefits.item3.title")}</h4>
          <p>{i18n.t("benefits.item3.description")}</p>
        </div>
      </section>

      <section className="container sectionVideos">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/isrvn7_PZvg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>

      <section className="container ourPartners">
        <h2>{i18n.t("beOne.title")}</h2>
        <div>
          <a href="https://p2prh.com.br/">
            <img src={P2PBlack} alt="Logo P2P" />
          </a>
          <a href="https://srdgestao.com.br/">
            <img src={SrdBlack} alt="Logo SRD" />
          </a>
          <a href="https://maqhin.com.br/">
            <img src={MaqhinBlack} alt="Logo Maquin" />
          </a>
          <a href="https://solvum.com.br">
            <img src={SolvumBlack} alt="Logo Solvum" />
          </a>
        </div>
      </section>

      <Footer />
    </>
  );
}
