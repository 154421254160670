import { useState } from "react";

import IconBR from "../../assets/images/icon-brazil.png";
import IconUSA from "../../assets/images/icon-usa.png";

import "./styles.scss";

const I18N_STORAGE_KEY = "i18nextLng";

export function FlagContainer() {
  const handleChangeLinguageEN = () => {
    localStorage.setItem(I18N_STORAGE_KEY, "pt-BR");
    window.location = window.location;
    setValueLanguage(localStorage.getItem(I18N_STORAGE_KEY));
  };

  const handleChangeLinguagePT = () => {
    localStorage.setItem(I18N_STORAGE_KEY, "en-US");
    window.location = window.location;
    setValueLanguage(localStorage.getItem(I18N_STORAGE_KEY));
  };

  const [valueLanguage, setValueLanguage] = useState(
    localStorage.getItem(I18N_STORAGE_KEY)
  );

  return (
    <div className="containerFlags">
      <button type="button" onClick={handleChangeLinguagePT}>
        <img src={IconUSA} alt="Linguagem PTBR" />
      </button>
      <button type="button" onClick={handleChangeLinguageEN}>
        <img src={IconBR} alt="Linguagem US" />
      </button>
    </div>
  );
}
