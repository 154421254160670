import { Icon } from "components/Icons";
import { footerNavigation } from "./mock";
import LogoWhite from "assets/images/logoWhite.png";

import "./styles.scss";
import { i18n } from "translate/i18n";

const now = new Date();
const currentYear = now.getFullYear();

export function Footer() {
  return (
    <footer className="footerContainer">
      <div>
        <div className="infoCamara">
          <img src={LogoWhite} alt="Logo Câmara de inovação aberta" />
          <p>{i18n.t("footer.description")}</p>
          <span>
            <a href="https://www.facebook.com/camaradeinovacao">
              <Icon name="face" />
            </a>
            <a href="https://www.linkedin.com/company/camara-de-inova%C3%A7%C3%A3o-aberta">
              <Icon name="linkedin" />
            </a>
            <a href="https://www.instagram.com/camaradeinovacao/">
              <Icon name="insta" />
            </a>
            <a href="https://www.youtube.com/channel/UCHG__SKCxTsBRhRyv524JgA">
              <Icon name="youtube" />
            </a>
          </span>
        </div>
        <div className="navigation">
          <span>Links</span>
          <ul>
            {footerNavigation.map((link) => (
              <li key={link.id}>
                <a href={link.href}>{link.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="contact">
          <span>{i18n.t("footer.talkToUs.title")}</span>
          <ul>
            <li>{i18n.t("footer.talkToUs.phone")}: (71) 99611-4396</li>
          </ul>
          <ul>
            <li>
              {i18n.t("footer.talkToUs.email")}: inove@camaradeinovacao.com
            </li>
            <li>
              {i18n.t("footer.talkToUs.address")}: Parque Tecnológico da Bahia,
              Sala 410A, Paralela, Salvador-BA
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <p>
            Copyright ©{currentYear} {i18n.t("footer.messageSolvum")}{" "}
            <a href="https://solvum.com.br"> Solvum Tecnologia </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
