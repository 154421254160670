import styles from "./styles.module.scss";

export function Insta(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.social}
    >
      <path
        d="M20.5918 31.9869C17.4121 31.9869 14.2455 31.9869 11.0658 31.9869C10.8711 31.9738 10.6765 31.9476 10.4818 31.9345C9.20994 31.9083 7.95105 31.8559 6.70515 31.5414C4.09653 30.8993 2.16278 29.4054 1.00772 26.9287C0.293924 25.4087 0.0732953 23.7707 0.0603171 22.1065C0.0213826 18.647 -0.017552 15.1744 0.00840441 11.715C0.0213826 10.2604 0.11223 8.79279 0.319881 7.36445C0.644335 5.13677 1.70855 3.276 3.52549 1.90008C5.08287 0.733825 6.87386 0.26208 8.78166 0.13104C9.53439 0.0786241 10.3001 0.039312 11.0528 0C14.2065 0 17.3602 0 20.5139 0C21.2537 0.039312 21.9934 0.0786241 22.7332 0.13104C24.031 0.209664 25.2899 0.432432 26.4839 0.969697C29.391 2.2932 30.9354 4.65192 31.4026 7.77068C31.4805 8.32105 31.5324 8.88452 31.5973 9.43489C31.5973 13.7199 31.5973 18.018 31.5973 22.303C31.5843 22.421 31.5584 22.5258 31.5454 22.6437C31.4805 23.2727 31.4416 23.9148 31.3377 24.5307C31.0003 26.5356 30.1567 28.2785 28.6253 29.6413C26.9771 31.122 24.9784 31.7117 22.824 31.8559C22.0843 31.9083 21.3316 31.9476 20.5918 31.9869ZM15.8288 29.1564C15.8288 29.1433 15.8288 29.1171 15.8288 29.104C16.6594 29.104 17.503 29.1302 18.3336 29.104C20.1246 29.0385 21.9286 29.0516 23.7066 28.8419C26.0426 28.5536 27.6519 27.217 28.3528 24.8976C28.6772 23.8362 28.7291 22.7224 28.7421 21.6216C28.781 18.7912 28.82 15.9607 28.794 13.1302C28.781 11.4398 28.7421 9.74938 28.5474 8.07207C28.2489 5.50368 26.6396 3.76085 24.1348 3.2629C23.0447 3.04013 21.9026 2.97461 20.7865 2.96151C18.0221 2.90909 15.2578 2.92219 12.4934 2.9353C10.9101 2.9353 9.31376 2.92219 7.7434 3.19738C5.34244 3.62981 3.69421 5.16298 3.20104 7.57412C2.99339 8.60933 2.9285 9.69697 2.90254 10.7584C2.85063 13.3268 2.82467 15.8952 2.85063 18.4767C2.87658 20.2719 2.9285 22.0803 3.08423 23.8624C3.23997 25.5266 3.99271 26.9156 5.39435 27.8984C6.47154 28.6454 7.70447 28.9337 8.98931 28.9861C11.2735 29.0647 13.5446 29.104 15.8288 29.1564Z"
        fill="#abb3c3"
      />
      <path
        d="M15.842 24.2158C11.3645 24.2289 7.70466 20.5466 7.69168 16.0126C7.67871 11.4918 11.3386 7.79643 15.829 7.78333C20.3195 7.78333 23.9274 11.4262 23.9663 15.9733C23.9793 20.4942 20.3324 24.2027 15.842 24.2158ZM10.5469 15.9864C10.5469 18.9348 12.883 21.3067 15.8031 21.3198C18.7231 21.3329 21.0722 18.9742 21.0852 16.0126C21.0981 13.0511 18.7491 10.6662 15.816 10.6662C12.8959 10.6662 10.5469 13.038 10.5469 15.9864Z"
        fill="#abb3c3"
      />
      <path
        d="M26.1722 7.44284C26.1722 8.50426 25.3416 9.35602 24.3033 9.36913C23.2521 9.38223 22.3826 8.51736 22.3826 7.45594C22.3826 6.39451 23.2132 5.54275 24.2514 5.54275C25.3156 5.52965 26.1722 6.36831 26.1722 7.44284Z"
        fill="#abb3c3"
      />
    </svg>
  );
}
export function Linkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      className={styles.social}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0H2C0.895 0 0 0.895 0 2V20C0 21.105 0.895 22 2 22H20C21.105 22 22 21.105 22 20V2C22 0.895 21.105 0 20 0ZM6.954 18H4.004V8.508H6.954V18ZM5.449 7.151C4.498 7.151 3.729 6.38 3.729 5.431C3.729 4.482 4.499 3.712 5.449 3.712C6.397 3.712 7.168 4.483 7.168 5.431C7.168 6.38 6.397 7.151 5.449 7.151ZM18.004 18H15.056V13.384C15.056 12.283 15.036 10.867 13.523 10.867C11.988 10.867 11.752 12.066 11.752 13.304V18H8.804V8.508H11.634V9.805H11.674C12.068 9.059 13.03 8.272 14.465 8.272C17.452 8.272 18.004 10.238 18.004 12.794V18V18Z"
        fill="#ABB3C3"
      />
    </svg>
  );
}
export function Youtube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      className={styles.social}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.582 2.186C19.352 1.326 18.674 0.648 17.814 0.418C16.254 2.08616e-07 10 0 10 0C10 0 3.746 2.08616e-07 2.186 0.418C1.326 0.648 0.648 1.326 0.418 2.186C-2.98023e-08 3.746 0 8 0 8C0 8 -2.98023e-08 12.254 0.418 13.814C0.648 14.674 1.326 15.352 2.186 15.582C3.746 16 10 16 10 16C10 16 16.254 16 17.814 15.582C18.675 15.352 19.352 14.674 19.582 13.814C20 12.254 20 8 20 8C20 8 20 3.746 19.582 2.186ZM8 10.598V5.402C8 5.017 8.417 4.777 8.75 4.969L13.25 7.567C13.583 7.759 13.583 8.241 13.25 8.433L8.75 11.031C8.417 11.224 8 10.983 8 10.598Z"
        fill="#ABB3C3"
      />
    </svg>
  );
}
export function Dribble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#abb3c3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="18"
      height="18"
      className={styles.social}
    >
      <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 18.535156 6 20.832031 6.953125 22.59375 8.5 C 22.40625 8.761719 22.152344 9.078125 21.75 9.46875 C 20.894531 10.296875 19.527344 11.292969 17.4375 12.0625 C 16.023438 9.449219 14.636719 7.484375 13.71875 6.25 C 14.449219 6.082031 15.214844 6 16 6 Z M 11.75 6.9375 C 12.570313 8.011719 14.03125 10.003906 15.5 12.65625 C 11.433594 13.734375 7.703125 13.871094 6.21875 13.875 C 6.882813 10.785156 8.960938 8.242188 11.75 6.9375 Z M 23.96875 9.96875 C 25.1875 11.582031 25.945313 13.578125 26 15.75 C 25.109375 15.550781 23.796875 15.355469 22.09375 15.34375 C 21.207031 15.339844 20.203125 15.402344 19.125 15.53125 C 18.875 14.960938 18.605469 14.410156 18.34375 13.875 C 20.585938 13.015625 22.113281 11.882813 23.125 10.90625 C 23.46875 10.570313 23.738281 10.261719 23.96875 9.96875 Z M 16.40625 14.46875 C 16.636719 14.933594 16.871094 15.410156 17.09375 15.90625 C 12.820313 17.089844 9.75 20.714844 8.4375 22.53125 C 6.925781 20.78125 6 18.5 6 16 C 6 15.957031 6 15.917969 6 15.875 C 7.351563 15.890625 11.648438 15.796875 16.40625 14.46875 Z M 22.09375 17.3125 C 23.878906 17.3125 25.113281 17.5625 25.84375 17.75 C 25.378906 20.414063 23.875 22.699219 21.75 24.1875 C 21.351563 21.796875 20.695313 19.523438 19.90625 17.4375 C 20.695313 17.359375 21.441406 17.3125 22.09375 17.3125 Z M 17.875 17.78125 C 18.773438 20.0625 19.527344 22.566406 19.90625 25.21875 C 18.710938 25.722656 17.382813 26 16 26 C 13.707031 26 11.589844 25.230469 9.90625 23.9375 C 10.933594 22.53125 13.953125 18.789063 17.875 17.78125 Z" />
    </svg>
  );
}
export function Face(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.social}
    >
      <path
        d="M13.0872 5.30712H15.9814V0.222766C15.4882 0.157246 13.7621 0 11.7635 0C7.59747 0 4.74227 2.64701 4.74227 7.5217V12.0033H0.13501V17.6904H4.74227V32H10.3878V17.6773H14.8004L15.5012 11.9902H10.3878V8.07207C10.3878 6.43407 10.8161 5.30712 13.0872 5.30712Z"
        fill="#abb3c3"
      />
    </svg>
  );
}
export function Twitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#abb3c3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width="16"
      height="16"
      className={styles.social}
    >
      <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
    </svg>
  );
}
