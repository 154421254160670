import { ArrowDown } from "phosphor-react";
import { i18n } from "translate/i18n";
import "./styles.scss";

interface StepListenItemProps {
  content: string;
  children: React.ReactNode;
  step: number;
}

export function StepListItem({ content, children, step }: StepListenItemProps) {
  return (
    <>
      <li className="stepListContainer">
        <div className="stepIcon">{children}</div>
        <strong>
          {i18n.t("scholarship.step")} {step}
        </strong>
        <span>{content}</span>
      </li>
      {step !== 6 && (
        <ArrowDown size={16} weight="bold" className="arrowSeparator" />
      )}
    </>
  );
}
