import { useHistory } from "react-router-dom";

import { motion } from "framer-motion";
import { itemVariants, variants } from "animations";

import { Header } from "../components/Header";
import { Button } from "components/Button";
import { SlidePartners } from "components/SlidePartners";

import BgHeroImg from "assets/svgs/bgHero.svg";
import PeoplesHero from "assets/images/peoplesHero.png";
import SecaoSobreNosImg from "assets/images/secao2_sobrenos.png";
import PointsPurpleImg from "assets/images/pointsPurple.png";

import { SlideSolution } from "components/SlideSolution";
import { SlideClient } from "components/SlideClient";
import { Footer } from "components/Footer";

import "styles/pages/home.scss";
import { i18n } from "../translate/i18n";

export function Home() {
  const navigation = useHistory();

  return (
    <>
      <Header />

      <div className="bgHero">
        <img src={BgHeroImg} alt="fundo sessão hero" />
      </div>

      <main className="hero containerHero">
        <motion.div variants={variants} initial="hidden" animate="visible">
          <motion.h1 variants={itemVariants}>
            {i18n.t("heroHome.title")}
          </motion.h1>
          <motion.p variants={itemVariants}>
            {i18n.t("heroHome.description")}
          </motion.p>
          <motion.div variants={itemVariants}>
            <Button>{i18n.t("heroHome.button")}</Button>
          </motion.div>
        </motion.div>
        <motion.div
          variants={itemVariants}
          initial="hidden"
          animate="visible"
          className="peoples"
        >
          <img src={PeoplesHero} alt="Grupo de pessoas reunidas" />
        </motion.div>
      </main>

      <section className="container aboutUs">
        <div className="imagePurple">
          <img src={PointsPurpleImg} alt="Pontos roxos" />
          <img
            className="mainImg"
            src={SecaoSobreNosImg}
            alt="Objetos tecnológicos, sobre nossa empresa"
          />
        </div>
        <div className="aboutText">
          <span>{i18n.t("aboutUs.title")}</span>
          <h2>{i18n.t("aboutUs.subTitle")}</h2>
          <p>{i18n.t("aboutUs.description")}</p>
          <div>
            <Button onClick={() => navigation.push("/acamara")}>
              {i18n.t("aboutUs.button")}
            </Button>
          </div>
        </div>
      </section>

      <section className="containerColumn">
        <h2>{i18n.t("ourPartners.title")}</h2>
        <SlidePartners />
      </section>

      <section className="offering containerColumn">
        <h2>{i18n.t("whatWeOffer.title")}</h2>

        <SlideSolution />
      </section>

      <section className="containerColumn" id="clients">
        <SlideClient />
      </section>

      <Footer />
    </>
  );
}
