import { useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "../Icons";
import LogoMobileImg from "../../assets/images/logo.png";

import "./styles.scss";
import { FlagContainer } from "components/FlagContainer";

export function NavbarMobile() {
  const [isShowMenu, setIsShowMenu] = useState(false);

  function handleClickShowMenu() {
    setIsShowMenu((state) => !state);
  }

  return (
    <>
      <nav className="navbarMobile">
        <div>
          <Link to="/">
            <img src={LogoMobileImg} alt="logo camara fria" />
          </Link>
        </div>

        <div className="btnContainer">
          <FlagContainer />

          <button
            onClick={handleClickShowMenu}
            className={`btnMenu ${isShowMenu && "bxActive"}`}
          ></button>
        </div>
      </nav>
      <nav className={`${isShowMenu && "showMenu"} menuMobileContainer`}>
        <ul>
          <li>
            <Link to="/acamara" className="navLink">
              A Câmara
            </Link>
          </li>
          <li>
            <a
              href="https://camaradeinovacao.com/blog/nossos-projetos/"
              className="navLink"
            >
              Soluções
            </a>
          </li>
          <li>
            <a
              href="https://camaradeinovacao.com/blog/conteudos/"
              className="navLink"
            >
              Blog
            </a>
          </li>
          <li>
            <Link to="/" className="navLink">
              Depoimentos
            </Link>
          </li>
          <li>
            <Link to="/trabalhe-conosco" className="navLink">
              Trabalhe conosco
            </Link>
          </li>
          <li>
            <Link to="/be-a-camnector" className="navLink">
              Camnector
            </Link>
          </li>
          <li>
            <a
              href="https://camaradeinovacao.com/blog/orcamento/"
              className="navLink"
            >
              Solicite um orçamento
            </a>
          </li>
          <li>
            <span>
              <a href="https://www.facebook.com/camaradeinovacao">
                <Icon name="face" />
              </a>
              <a href="https://www.linkedin.com/company/camara-de-inova%C3%A7%C3%A3o-aberta">
                <Icon name="linkedin" />
              </a>
              <a href="https://www.instagram.com/camaradeinovacao/">
                <Icon name="insta" />
              </a>
              <a href="https://www.youtube.com/channel/UCHG__SKCxTsBRhRyv524JgA">
                <Icon name="youtube" />
              </a>
            </span>
          </li>
        </ul>
      </nav>
    </>
  );
}
