import styles from './styles.module.scss';

export function Clock() {
  return (
    <svg
      className={styles.icon}
      width="90"
      height="90"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 15V25M28 39L20 34M20.5 71L28 66M52 80.5V89.5M76.5 66.5L84 71.5M76 39L84 34"
        stroke="#14145E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="52" cy="52" r="49" stroke="#14145E" strokeWidth="6" />
    </svg>
  );
}

export function Sheets() {
  return (
    <svg
      className={styles.icon}
      width="90"
      height="90"
      viewBox="0 0 95 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 14C7.5 13.5 3 17.5 3 22V92.5C3 97 8 100.5 11.5 100.5H65.5C69.5 100.5 72.5 96 72.5 92.5V89M42.5 33.5005H74.5M42.5 46.5005H74.5M42.5 59.5005H74.5M35.5 3C30 3.00049 25 6 25 12.5V70.5C25 75.5005 29.5 79 33 79H83.5C88 79 92 74.5005 92 70.5V23C92 20.0005 75.5 3 72.5 3H35.5Z"
        stroke="#14145E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Check(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={styles.icon}
      width="90"
      height="90"
      viewBox="0 0 104 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 51L49.5 65.5L74 41.5M3 11V92.5C3 97 7.5 100 12 100H92C96.5 100 100.5 97 100.5 92.5V11C100.5 6.5 95.5 3 91 3H12C7 3 3 6 3 11Z"
        stroke="#14145E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
