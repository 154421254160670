import { Icon } from "components/Icons";

import { v4 as uuidV4 } from "uuid";

import "./styles.scss";
import { StepListItem } from "components/StepListItem";
import { i18n } from "translate/i18n";

export function ScholarshipProgram() {
  const stepsContent = [
    {
      content: i18n.t("scholarship.step1.content"),
      icon: "email",
    },
    {
      content: i18n.t("scholarship.step2.content"),
      icon: "scroll",
    },
    {
      content: i18n.t("scholarship.step3.content"),
      icon: "calendar",
    },
    {
      content: i18n.t("scholarship.step4.content"),
      icon: "screen",
    },
    {
      content: i18n.t("scholarship.step5.content"),
      icon: "clockwise",
    },
    {
      content: i18n.t("scholarship.step6.content"),
      icon: "network",
    },
  ];

  return (
    <>
      <main>
        <div className="container scholarshipContent">
          <ul className="swiperContainerShip">
            {stepsContent.map((item, index) => (
              <StepListItem
                key={uuidV4()}
                step={index + 1}
                content={item.content}
              >
                <Icon name={item.icon} />
              </StepListItem>
            ))}
          </ul>
        </div>
      </main>
    </>
  );
}
