import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import LogoMaqhin from "assets/images/maqhin.png";
import LogoSolvum from "assets/svgs/logoSolvum.svg";
import LogoP2P from "assets/images/people2people.png";
import LogoSRD from "assets/images/parceiroSRD.png";

import "./styles.scss";

type Partners = {
  id: number;
  image: string;
  alt: string;
  url: string;
};

export const allPartners: Array<Partners> = [
  {
    id: 1,
    image: LogoMaqhin,
    alt: "Logo Maqhin",
    url: "https://maqhin.com.br/",
  },
  {
    id: 2,
    image: LogoSolvum,
    alt: "Logo Solvum",
    url: "https://solvum.com.br/",
  },
  {
    id: 3,
    image: LogoP2P,
    alt: "Logo People2People",
    url: "https://p2prh.com.br/",
  },
  {
    id: 4,
    image: LogoSRD,
    alt: "Logo Solvum",
    url: "https://srdgestao.com.br/",
  },
];

export function SlidePartners() {
  const params = {
    slidesPerView: 4,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      "200": {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      "520": {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      "964": {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    loop: true,
    grabCursor: true,
    autoplay: true,
  };

  return (
    <Swiper {...params} className="swiperContainer">
      {allPartners.map((partner) => (
        <SwiperSlide key={partner.id} className="card">
          <a href={partner.url}>
            <img src={partner.image} alt={partner.alt} />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
