import { i18n } from "translate/i18n";

type Link = {
  id: number;
  name: string;
  href: string;
};

export const footerNavigation: Link[] = [
  { id: 1, name: i18n.t("footer.links.theChamber"), href: "/acamara" },
  {
    id: 2,
    name: i18n.t("footer.links.solutions"),
    href: "https://camaradeinovacao.com/blog/nossos-projetos/",
  },
  { id: 3, name: i18n.t("footer.links.blog"), href: "https://camaradeinovacao.com/blog/conteudos/" },
  { id: 4, name: i18n.t("footer.links.testimonial"), href: "/" },
];
