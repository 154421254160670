import { Link } from "react-router-dom";
import { itemVariants, variants } from "animations";
import { Button } from "components/Button";
import { Header } from "components/Header";
import { motion } from "framer-motion";
import "styles/pages/workWithUs.scss";

import PeoplesWorkingImg from "assets/images/peoples-work.png";
import HappyPeoples from "assets/images/happy-peoples.png";
import BgTime from "assets/images/background-time.png";
import { Footer } from "components/Footer";
import { Icon } from "components/Icons";
import { i18n } from "translate/i18n";

export function TrabalheConosco() {
  return (
    <>
      <Header />

      <main
        className="heroContainer"
        style={{ backgroundImage: `url(${BgTime})` }}
      >
        <motion.div variants={variants} initial="hidden" animate="visible">
          <motion.h1 variants={itemVariants}>{i18n.t("bePartOfTheTeam.title")}</motion.h1>
          <motion.span variants={itemVariants}>
            <Link to="/">{i18n.t("bePartOfTheTeam.redirect.home")}</Link>
            <Link to="/trabalhe-conosco">{i18n.t("bePartOfTheTeam.redirect.workWithUs")}</Link>
          </motion.span>
        </motion.div>
      </main>

      <section className="container manyOptions">
        <div className="optionsText">
          <h2>{i18n.t("whyWorkUs.title")}</h2>
          <p>{i18n.t("whyWorkUs.description")}</p>
          <Button>{i18n.t("whyWorkUs.button")}</Button>
        </div>
        <div className="optionsImages">
          <img src={PeoplesWorkingImg} alt="Pessoas felizes com o trabalho" />
          <img src={HappyPeoples} alt="Pessoas felizes segurando uma peça" />
        </div>
      </section>

      <section className="container aboutUsGroup">
        <div className="groupImages">
          <div className="flex1">
            <div>
              <Icon name="check" />
              <p>Cooperação</p>
            </div>
          </div>
          <div className="flex2">
            <div className="item2">
              <Icon name="sheets" />
              <p>Inovação</p>
            </div>
            <div className="item3">
              <Icon name="clock" />
              <p>Agilidade</p>
            </div>
          </div>
        </div>
        <div className="groupText">
          <h2>{i18n.t("AboutOurTeam.title")}</h2>
          <p>
          {i18n.t("AboutOurTeam.description")}
          </p>
          <Button>{i18n.t("AboutOurTeam.button")}</Button>
        </div>
      </section>

      <Footer />
    </>
  );
}
